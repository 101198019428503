<template>
  <div class="static-page page">
    <div class="static-page__container">
      <h1 class="static-page__title">{{ page.title }}</h1>
      <div v-if="page.description" class="static-page__description">
        <EditorJSComponent :text="JSON.parse(page.description)" />
      </div>
    </div>
  </div>
</template>

<script>
import PAGE from "@/graphql/queries/page.graphql";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "StaticPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: PAGE,
        variables: {
          link: route.params.link,
        },
      })
      .then(({ data }) => {
        store.state.static_page = data.page;
        store.state.categories = data.categories;
        store.state.pages = data.pages;
        if (data.exchange_rates) store.state.exchange_rates = data.exchange_rates;
      });
  },
  computed: {
    page() {
      return this.$store.state.static_page || {};
    },
  },
  components: {
    EditorJSComponent,
  },
  metaInfo() {
    return {
      title: this.page.meta_title,
      description: this.page.meta_description,
      keywords: this.page.meta_keywords,
    };
  },
};
</script>

<style lang="stylus">
.static-page {
  display grid
  padding 32px 15px 48px
  +below(860px) {
    padding 15px
  }

  &__container {
    display grid
    align-items start
    gap 24px
    margin 0 auto
  }

  &__title {
    margin 0
  }
}
</style>
